<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <br />
    <submit-button
      :isLoading="isLoading"
      @submit="save('submit')"
      text="Save/Update"
    ></submit-button>

    <div class="container-fluid">
      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">
            <back-button link="/tc-ins"></back-button>
            {{ type == "tcin" ? "Tc In" : "Tc Out" }}
            <loading :isLoading="isLoading"></loading>
          </h5>
          <div class="row">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-4">
                  <Payments
                    :form="form"
                    :expenseRevenueCodes="expenseRevenueCodes"
                    :fuelTypes="fuelTypes"
                    :voyages="form.voyages"
                    :delayTypes="delayTypes"
                    @updateTcInPayment="updateTcInPayment"
                    :paymentTypes="paymentTypes"
                    :approvalTypes="approvalTypes"
                  ></Payments>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-8 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><router-link to="/">Dashboard</router-link></li>
            <li>Data Center</li>
            <li><router-link to="/tc-ins">Tc Ins</router-link></li>
            <li class="active">Create</li>
          </ol>
        </div>
        <!-- /Breadcrumb -->
      </div>
      <!-- /Title -->

      <!-- Row -->
      <div class="row">
        <div class="col-md-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="row">
                  <div class="col-sm-12 col-xs-12">
                    <div class="form-wrap form-horizontal">
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Vessel*</label
                            >
                            <div class="col-sm-9">
                              <my-select
                                :selection.sync="form.vessel_id"
                                :suggestions="vessels"
                                placeholder="Enter Vessel"
                                @change="onVesselChanged"
                              ></my-select>
                              <span class="error-block">{{
                                errors.vessel_id
                              }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Date*</label>
                            <div class="col-sm-9">
                              <my-datepicker
                                :date.sync="form.date"
                                placeholder="Enter Date"
                              ></my-datepicker>
                              <span class="error-block">{{ errors.date }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Status</label>
                            <div class="col-sm-9">
                              <my-select
                                :selection.sync="form.status_id"
                                :suggestions="tcInStatuses"
                                placeholder="Enter Status"
                              ></my-select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >TC Code*</label
                            >
                            <div class="col-sm-9">
                              <input
                                disabled
                                v-uppercase
                                type="text"
                                class="form-control"
                                placeholder="TC Code"
                                v-model="form.tc_code"
                              />
                              <span class="error-block">{{
                                errors.tc_code
                              }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Company</label
                            >
                            <div class="col-sm-9">
                              <my-select
                                :selection.sync="form.company_id"
                                :suggestions="companies"
                                placeholder="Enter company"
                              ></my-select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Billing Period</label
                            >
                            <div class="col-sm-9">
                              <my-select
                                :selection.sync="form.billing_period"
                                :suggestions="billingPeriods"
                                placeholder="Enter billing period"
                              ></my-select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">{{
                              form.is_tc_in_out == "tcin"
                                ? "Owner"
                                : "Charterer"
                            }}</label>
                            <div class="col-sm-9">
                              <my-select
                                :selection.sync="form.owner_id"
                                :suggestions="
                                  form.is_tc_in_out == 'tcin'
                                    ? owners
                                    : charterers
                                "
                                :placeholder="
                                  form.is_tc_in_out == 'tcin'
                                    ? 'Enter Owner'
                                    : 'Enter Charterer'
                                "
                              ></my-select>
                              <span class="error-block">{{
                                errors.owner_id
                              }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Department</label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="text"
                                class="form-control"
                                placeholder="Department"
                                v-model="form.add_1"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Billing Schedule</label
                            >
                            <div class="col-sm-9">
                              <my-select
                                :selection.sync="form.billing_schedule"
                                :suggestions="billingSchedules"
                                placeholder="Enter billing schedule"
                              ></my-select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">{{
                              this.form.is_tc_in_out == "tcin"
                                ? "Beneficiary"
                                : "Bill Via"
                            }}</label>
                            <div class="col-sm-9">
                              <my-select
                                :selection.sync="form.beneficiary_id"
                                :suggestions="
                                  form.is_tc_in_out == 'tcin'
                                    ? owners
                                    : charterers
                                "
                                :placeholder="
                                  form.is_tc_in_out == 'tcin'
                                    ? 'Enter Beneficiary'
                                    : 'Enter Bill Via'
                                "
                              ></my-select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Trade Area</label
                            >
                            <div class="col-sm-9">
                              <my-select
                                :selection.sync="form.trade_area_id"
                                :suggestions="tradeAreas"
                                placeholder="Enter trade area"
                              ></my-select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Rebillable Admin Fee %</label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="text"
                                class="form-control"
                                placeholder="Rebillable Admin Fee"
                                v-model="form.rebillable_admin_fee_percent"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Laycan From</label
                            >
                            <div class="col-sm-9">
                              <my-datepicker
                                :date.sync="form.laycan_from"
                                placeholder="Enter Laycan From"
                              ></my-datepicker>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Ref. No</label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="text"
                                class="form-control"
                                placeholder="Ref. No"
                                v-model="form.ref_no"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Curr/Exch. Rate</label
                            >
                            <div class="col-sm-5">
                              <my-select
                                :selection.sync="form.exc_currency_id"
                                :suggestions="currencies"
                                placeholder="Enter Currency"
                              ></my-select>
                            </div>
                            <div class="col-sm-4">
                              <input
                                v-uppercase
                                type="number"
                                class="form-control"
                                placeholder="Curr/Exch. Rate"
                                v-model="form.exc_currency_rate"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Laycan To</label
                            >
                            <div class="col-sm-9">
                              <my-datepicker
                                :date.sync="form.laycan_to"
                                placeholder="Enter Laycan To"
                              ></my-datepicker>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Payment Terms</label
                            >
                            <div class="col-sm-9">
                              <my-select
                                :selection.sync="form.payment_term_id"
                                :suggestions="paymentTerms"
                                placeholder="Enter payment term"
                              ></my-select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Ref Contract</label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="text"
                                class="form-control"
                                placeholder="Ref Contract"
                                v-model="form.country_code"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Fixed By</label
                            >
                            <div class="col-sm-9">
                              <my-select
                                :selection.sync="form.fixed_by"
                                :suggestions="users"
                                placeholder="Select user"
                              ></my-select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Contract Type</label
                            >
                            <div class="col-sm-9">
                              <my-select
                                :selection.sync="form.contract_type_id"
                                :suggestions="contractTypes"
                                placeholder="Enter contract type"
                              ></my-select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Conf Date GMT</label
                            >
                            <div class="col-sm-9">
                              <my-datepicker
                                :date.sync="form.conf_date_gmt"
                                placeholder="Select Conf. Date"
                              ></my-datepicker>
                            </div>
                          </div>
                        </div>
                      </div>

                      <br />

                      <Duration :form="form" :durationUnits="durationUnits">
                      </Duration>

                      <br />
                      <Ports
                        :form="form"
                        :portNames="portNames"
                        @onPortsUpdated="onPortsUpdated"
                      ></Ports>

                      <Pricing
                        :form="form"
                        :pricingRateTypes="pricingRateTypes"
                        :pricingCodes="pricingCodes"
                        :pricingLocks="pricingLocks"
                        :durationUnits="durationUnits"
                        @onPortsUpdated="onPortsUpdated"
                      ></Pricing>

                      <Broker
                        :form="form"
                        :addresses="addresses"
                        :brokerRateTypes="brokerRateTypes"
                        :brokerPaymentMethods="brokerPaymentMethods"
                        :brokerCommissionables="brokerCommissionables"
                        @onPortsUpdated="onPortsUpdated"
                      ></Broker>

                      <Expenses
                        :form="form"
                        :expenseRevenueCodes="expenseRevenueCodes"
                        :expenseRateTypes="expenseRateTypes"
                      ></Expenses>

                      <Bunkers :form="form" :fuelTypes="fuelTypes"></Bunkers>

                      <errors :data="errors" />

                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group mb-0">
                            <div class="col-sm-9">
                              <submit-button
                                :isLoading="isLoading"
                                @submit="save('submit')"
                                text="Save/Update"
                              ></submit-button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Row -->
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import axios from "axios";
import Duration from "./duration.vue";
import Ports from "./ports.vue";
import Pricing from "./pricing.vue";
import Broker from "./broker.vue";
import Expenses from "./expenses.vue";
import Bunkers from "./bunkers.vue";
import Payments from "./payments/index.vue";
import moment from "moment";

export default {
  name: "TcIn",
  data() {
    return {
      form: {
        is_tc_in_out: "",
        vessel_id: "",
        tc_code: "",
        owner_id: "",
        beneficiary_id: "",
        laycan_from: "",
        laycan_to: "",
        date: "",
        fixed_by: "",
        company_id: "",
        department_id: "",
        trade_area_id: "",
        ref_no: "",
        payment_term_id: "",
        contract_type_id: "",
        status_id: "",
        billing_period: "",
        billing_schedule: "",
        rebillable_admin_fee_percent: 0,
        exc_currency_id: 26,
        exc_currency_rate: 1,
        ref_contract: "",
        conf_date_gmt: "",
        min_duration: 0,
        min_duration_unit: "Days",
        min_duration_plus_days: 0,
        min_duration_minus_days: 0,
        min_estimated_redelivery: "",
        max_duration: 0,
        max_duration_unit: "Days",
        max_duration_plus_days: 0,
        max_duration_minus_days: 0,
        max_estimated_redelivery: "",
        delivery_port_id: "",
        delivery_est_gmt: "",
        delivery_actual_gmt: "",
        delivery_difference: 0,
        delivery_remarks: "",
        redelivery_port_id: "",
        redelivery_est_gmt: "",
        redelivery_actual_gmt: "",
        redelivery_difference: 0,
        redelivery_remarks: "",
        est_duration: 0,
        actual_duration: 0,
        use_local_time: "",
        remarks: "",
        notes_to_operations: "",
        tc_in_pricings: [],
        tc_in_brokers: [],
        tc_in_expenses: [],
        tc_in_bunkers: [],
        tc_in_payments: [],
      },
      vessels: [],
      addresses: [],
      billingPeriods: [],
      billingSchedules: [],
      tradeAreas: [],
      paymentTerms: [],
      contractTypes: [],
      tcInStatuses: [],
      currencies: [],
      portNames: [],
      durationUnits: [],
      pricingRateTypes: [],
      brokerRateTypes: [],
      brokerPaymentMethods: [],
      brokerCommissionables: [],
      pricingCodes: [],
      pricingLocks: [],
      expenseRevenueCodes: [],
      expenseRateTypes: [],
      fuelTypes: [],
      companies: [],
      owners: [],
      isLoading: false,
      users: [],
      voyages: [],
      delayTypes: [],
      paymentTypes: [],
      charterers: [],
      type: "",
      attachment: "",
    };
  },
  // props: ["form"],
  watch: {
    tcInStatuses: "onTcInStatusUpdated",
    billingSchedules: "onBillingSchedulesUpdated",
  },
  components: {
    Duration,
    Ports,
    Pricing,
    Broker,
    Expenses,
    Bunkers,
    Payments,
  },
  mounted() {
    this.form.site_id = this.site.id;
    this.type = this.$route.query.type;
    this.form.is_tc_in_out = this.$route.query.type;
    this.getMasters();
  },
  methods: {
    onVesselChanged() {
      let vessel = this.vessels.find(
        (vessel) => vessel.id == this.form.vessel_id
      );
      if (vessel && !this.form.id) {
        this.form.tc_code =
          vessel.vessel_code +
          (this.$route.query.type == "tcin" ? "-I" : "-O") +
          "000" +
          (parseFloat(
            vessel.tc_ins.filter(
              (tcIn) => tcIn.is_tc_in_out == this.$route.query.type
            ).length
          ) +
            1);
      }
    },
    onTcInStatusUpdated() {
      let tcInStatus = this.tcInStatuses.find(
        (status) => status.name == "FIXED"
      );
      if (tcInStatus) this.form.status_id = tcInStatus.id;
    },
    onBillingSchedulesUpdated() {
      let billingSchedule = this.billingSchedules.find(
        (billingSchedule) => billingSchedule.name == "In Advance"
      );
      if (billingSchedule) this.form.billing_schedule = billingSchedule.id;
    },
    onPortsUpdated(elementName) {
      let toDateTime;
      let fromDateTime;
      let diffInSeconds = 0;
      if (
        this.form.delivery_est_gmt &&
        this.form.est_duration &&
        elementName == "est_duration"
      ) {
        fromDateTime = this.form.delivery_est_gmt;
        let duration = this.form.est_duration;
        toDateTime = moment(this.form.delivery_est_gmt)
          .add(duration * 86400, "seconds")
          .format("MM/DD/YYYY HH:mm");
        this.form.redelivery_est_gmt = toDateTime;
      }
      // else
      // Port Dates Changed
      if (
        this.form.delivery_est_gmt &&
        this.form.redelivery_est_gmt &&
        (elementName == "delivery_est_gmt" ||
          elementName == "redelivery_est_gmt")
      ) {
        fromDateTime = this.form.delivery_est_gmt;
        toDateTime = this.form.redelivery_est_gmt;
        diffInSeconds = moment(toDateTime).diff(
          moment(fromDateTime),
          "seconds"
        );
        this.form.est_duration = parseFloat(diffInSeconds) / 86400;
      }

      if (
        this.form.delivery_actual_gmt &&
        this.form.redelivery_actual_gmt &&
        (elementName == "delivery_actual_gmt" ||
          elementName == "redelivery_actual_gmt")
      ) {
        fromDateTime = this.form.delivery_actual_gmt;
        toDateTime = this.form.redelivery_actual_gmt;
        diffInSeconds = moment(toDateTime).diff(
          moment(fromDateTime),
          "seconds"
        );
        this.form.actual_duration = parseFloat(diffInSeconds) / 86400;
      }

      if (!this.form.id) {
        if (this.form.tc_in_pricings.length > 0) {
          if (fromDateTime) this.form.tc_in_pricings[0].from_gmt = fromDateTime;
          if (toDateTime) this.form.tc_in_pricings[0].to_gmt = toDateTime;
          diffInSeconds = moment(toDateTime).diff(
            moment(fromDateTime),
            "seconds"
          );
          if (diffInSeconds != 0)
            this.form.tc_in_pricings[0].duration =
              parseFloat(diffInSeconds) / 86400;
        }
        if (this.form.tc_in_brokers.length > 0) {
          if (fromDateTime) this.form.tc_in_brokers[0].from_gmt = fromDateTime;
          if (toDateTime) this.form.tc_in_brokers[0].to_gmt = toDateTime;
          this.form.tc_in_brokers[0].duration =
            parseFloat(diffInSeconds) / 86400;
        }
      }

      // Pricing Dates Changed
      this.form.tc_in_pricings.forEach((tcInPricing) => {
        fromDateTime = tcInPricing.from_gmt;
        toDateTime = tcInPricing.to_gmt;
        diffInSeconds = moment(toDateTime).diff(
          moment(fromDateTime),
          "seconds"
        );
        tcInPricing.duration = parseFloat(diffInSeconds) / 86400;
      });
    },
    updateTcInPayment() {
      this.form.tc_in_payments.forEach((tcInPayment) => {
        // let fromDate = moment(tcInPayment.period_from);
        // let toDate = moment(tcInPayment.period_to);
        // let diffInSeconds = toDate.diff(fromDate, "seconds");
        // tcInPayment.days = parseFloat(diffInSeconds) / 86400;
        let amount = 0;
        tcInPayment.tc_in_payment_details.forEach((tcInPaymentDetail) => {
          tcInPaymentDetail.amount_usd = tcInPaymentDetail.amount;
          if (tcInPaymentDetail.include_in_invoice == "1")
            amount = parseFloat(amount) + parseFloat(tcInPaymentDetail.amount);
        });
        tcInPayment.amount = amount;
      });
      // this.save()
    },
    async save(but = "") {
      // console.log(this.form);
      let formTest = {};
      // Object.assign(formTest, this.form);
      // let formData = this.jsonToFormData(formTest);
      // try {
      //   this.isLoading = true;
      //   await axios.post("/tc_ins", this.form, {
      //     headers: {
      //       "Content-Type": "multipart/form-data",
      //     },
      //   });
      //   this.isLoading = false;
      //   if (but == "submit")
      //     this.$router.push("/tc-ins?type=" + this.$route.query.type);
      // } catch (e) {
      //   this.isLoading = false;
      // }
      try {
        this.isLoading = true;
        await axios.post("/tc_ins", this.form);
        this.isLoading = false;
        if (but == "submit")
          this.$router.push("/tc-ins?type=" + this.$route.query.type);
      } catch (e) {
        this.isLoading = false;
      }
    },
    jsonToFormData(data) {
      const formData = new FormData();
      this.buildFormData(formData, data);
      return formData;
    },
    buildFormData(formData, data, parentKey) {
      if (
        data &&
        typeof data === "object" &&
        !(data instanceof Date) &&
        !(data instanceof File)
      ) {
        Object.keys(data).forEach((key) => {
          this.buildFormData(
            formData,
            data[key],
            parentKey ? `${parentKey}[${key}]` : key
          );
        });
      } else {
        const value = data == null ? "" : data;
        formData.append(parentKey, value);
      }
    },
    async getMasters() {
      this.isLoading = true;
      let masters = await axios.get("/tc_ins/masters");
      masters = masters.data;
      masters.vessels.forEach((vessel) => {
        this.vessels.push({
          id: vessel.id,
          vessel_code: vessel.vessel_code,
          name: vessel.vessel_code + " - " + vessel.vessel_name,
          tc_ins: vessel.tc_ins,
        });
      });
      masters.addresses.forEach((address) => {
        this.addresses.push({
          id: address.id,
          name: address.short_name + " - " + address.full_name,
        });
      });
      masters.billing_periods.forEach((billingPeriod) => {
        this.billingPeriods.push({
          id: billingPeriod,
          name: billingPeriod,
        });
      });
      masters.billing_schedules.forEach((billingSchedule) => {
        this.billingSchedules.push({
          id: billingSchedule,
          name: billingSchedule,
        });
      });
      masters.trade_areas.forEach((trade_area) => {
        this.tradeAreas.push({
          id: trade_area.id,
          name: trade_area.code + " - " + trade_area.description,
        });
      });
      masters.currencies.forEach((currency) => {
        this.currencies.push({
          id: currency.id,
          name: currency.name + " - " + currency.description,
        });
      });
      masters.payment_terms.forEach((paymentTerm) => {
        this.paymentTerms.push({
          id: paymentTerm.id,
          name: paymentTerm.code + " - " + paymentTerm.description,
        });
      });
      masters.contract_types.forEach((contractType) => {
        this.contractTypes.push({
          id: contractType.id,
          name: contractType.type,
        });
      });
      masters.tc_in_statuses.forEach((tc_in_status) => {
        this.tcInStatuses.push({
          id: tc_in_status.id,
          name: tc_in_status.status,
        });
      });
      masters.duration_units.forEach((durationUnit) => {
        this.durationUnits.push({
          id: durationUnit,
          name: durationUnit,
        });
      });
      masters.port_names.forEach((port_name) => {
        this.portNames.push({
          id: port_name.id,
          name: port_name.port_name,
        });
      });
      masters.pricing_rate_types.forEach((pricingRateType) => {
        this.pricingRateTypes.push({
          id: pricingRateType,
          name: pricingRateType,
        });
      });
      masters.broker_rate_types.forEach((brokerRateType) => {
        this.brokerRateTypes.push({
          id: brokerRateType,
          name: brokerRateType,
        });
      });
      masters.pricing_codes.forEach((pricing_code) => {
        this.pricingCodes.push({
          id: pricing_code.id,
          name: pricing_code.code + " - " + pricing_code.description,
        });
      });
      masters.pricing_locks.forEach((pricingLock) => {
        this.pricingLocks.push({
          id: pricingLock,
          name: pricingLock,
        });
      });
      masters.broker_payment_methods.forEach((brokerPaymentMethod) => {
        this.brokerPaymentMethods.push({
          id: brokerPaymentMethod,
          name: brokerPaymentMethod,
        });
      });
      masters.broker_commissionables.forEach((brokerCommissionable) => {
        this.brokerCommissionables.push({
          id: brokerCommissionable,
          name: brokerCommissionable,
        });
      });
      masters.expense_revenue_codes.forEach((expense_revenue_code) => {
        this.expenseRevenueCodes.push({
          id: expense_revenue_code.id,
          name: expense_revenue_code.description,
        });
      });
      masters.expense_rate_types.forEach((expenseRateType) => {
        this.expenseRateTypes.push({
          id: expenseRateType,
          name: expenseRateType,
        });
      });
      masters.companies.forEach((company) => {
        this.companies.push({
          id: company.id,
          name: company.short_name + " -" + company.full_name,
        });
      });
      masters.owners.forEach((company) => {
        this.owners.push({
          id: company.id,
          name: company.short_name + " -" + company.full_name,
        });
      });
      masters.charterers.forEach((company) => {
        this.charterers.push({
          id: company.id,
          name: company.short_name + " -" + company.full_name,
        });
      });
      masters.users.forEach((user) => {
        this.users.push({
          id: user.id,
          name: user.first_name + " " + user.last_name,
        });
      });
      this.delayTypes = masters.delay_types;
      this.fuelTypes = masters.fuel_types;
      masters.paymentTypes.forEach((paymentType) => {
        this.paymentTypes.push({
          id: paymentType,
          name: paymentType,
        });
      });
      this.approvalTypes = [
        {
          id: "Approved",
          name: "Approved",
        },
        {
          id: "Not Approved",
          name: "Not Approved",
        },
      ];
      this.isLoading = false;
      this.updateFormData();
    },
    async updateFormData() {
      this.isLoading = true;
      if (!this.$route.params.tcInId) {
        this.isLoading = false;
        return;
      }
      let form = await axios.get(`/tc_ins/${this.$route.params.tcInId}`);
      this.form = form.data.data;
      this.form.is_tc_in_out = this.$route.query.type;
      (this.form.vessel_id = this.form.vessel_id || ""),
        (this.form.owner_id = this.form.owner_id || ""),
        (this.form.billing_period = this.form.billing_period || ""),
        (this.form.billing_schedule = this.form.billing_schedule || ""),
        (this.form.beneficiary_id = this.form.beneficiary_id || ""),
        (this.form.fixed_by = this.form.fixed_by || ""),
        (this.form.company_id = this.form.company_id || ""),
        (this.form.department_id = this.form.department_id || ""),
        (this.form.trade_area_id = this.form.trade_area_id || ""),
        (this.form.payment_term_id = this.form.payment_term_id || ""),
        (this.form.contract_type_id = this.form.contract_type_id || ""),
        (this.form.status_id = this.form.status_id || ""),
        (this.form.exc_currency_id = this.form.exc_currency_id || ""),
        (this.form.delivery_port_id = this.form.delivery_port_id || ""),
        (this.form.redelivery_port_id = this.form.redelivery_port_id || ""),
        (this.form.min_duration_unit = this.form.min_duration_unit || ""),
        (this.form.max_duration_unit = this.form.max_duration_unit || ""),
        this.form.tc_in_pricings.forEach((tc_in_pricing) => {
          tc_in_pricing.rate_type = tc_in_pricing.rate_type || "";
          tc_in_pricing.pricing_code_id = tc_in_pricing.pricing_code_id || "";
          tc_in_pricing.lock = tc_in_pricing.lock || "";
          tc_in_pricing.duration_unit = tc_in_pricing.duration_unit || "";
        });
      this.form.tc_in_brokers.forEach((tc_in_broker) => {
        tc_in_broker.broker_id = tc_in_broker.broker_id || "";
        tc_in_broker.rate_type = tc_in_broker.rate_type || "";
        tc_in_broker.payment_method = tc_in_broker.payment_method || "";
        tc_in_broker.is_claim_commissionable =
          tc_in_broker.is_claim_commissionable || "";
      });
      this.form.tc_in_expenses.forEach((tc_in_expense) => {
        tc_in_expense.expense_revenue_code_id =
          tc_in_expense.expense_revenue_code_id || "";
        tc_in_expense.rate_type = tc_in_expense.rate_type || "";
      });
      this.isLoading = false;
    },
  },
  // attachmentNewAdded(attachmentData) {
  //   console.log(attachmentData);
  //   // this.attachment = attachment;
  // },
  // async handleFileUpload() {
  //   this.isLoading = true;
  //   let attachment = this.attachment;
  //   console.log(attachment);

  //   // const id = this.office_job_scheduler.id;

  //   let formData = new FormData();
  //   // formData.append("id", id);
  //   formData.append("attachment", attachment);

  //   // await axios
  //   //   .post("upload_office_job_scheduler_attachment", formData, {
  //   //     headers: {
  //   //       "Content-Type": "multipart/form-data",
  //   //     },
  //   //   })
  //   //   .catch(function () {
  //   //     console.log("FAILURE!!");
  //   //   });
  //   // if (this.career_request) {
  //   //   this.success = true;
  //   //   this.form = [];
  //   // }
  //   this.isLoading = false;
  // },
};
</script>
